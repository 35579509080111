import React from 'react'

import { GenerateWagtailPage } from '@/components/GenerateWagtailPage'
import { ssrPageFetcher } from '@/utils/ssrPageFetcher'

import type {
  GetServerSidePropsContext,
  InferGetServerSidePropsType
} from 'next/types'

const Home = ({
  baseUrl,
  footerMenu,
  page
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  if (!page) return null

  return (
    <GenerateWagtailPage
      baseUrl={baseUrl}
      footerMenu={footerMenu}
      page={page}
    />
  )
}

export async function getServerSideProps(_context: GetServerSidePropsContext) {
  return ssrPageFetcher(_context)
}

export default Home
